import React from 'react';
import { Text, Divider, TextButton, TextButtonPriority } from 'wix-ui-tpa';
import { useBi, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ChevronDown, ChevronUp } from '@wix/wix-ui-icons-common/on-stage';
import { bookingsCalendarClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { classes, st } from './SectionHeader.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { DataHooks } from './constants';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { WidgetComponents } from '../../../../utils/bi/consts';

export type SectionHeaderProps = {
  title: string;
  suffix?: React.ReactNode;
  isCollapsed?: boolean;
  setIsCollapsed?(isCollapsed: boolean): void;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  suffix,
  isCollapsed,
  setIsCollapsed,
}) => {
  const { isMobile, isRTL } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const logger = useBi();

  const isCollapseServiceDetailsEnabled = experiments.enabled(
    'specs.bookings.calendar.collapseServiceDetails',
  );

  function toggleCollapse() {
    void logger.report(
      bookingsCalendarClick({
        component: WidgetComponents.BOOKING_DETAILS,
        element: isCollapsed ? 'expand' : 'collapse',
      }),
    );

    setIsCollapsed!(!isCollapsed);
  }

  return isCollapseServiceDetailsEnabled && setIsCollapsed ? (
    <div
      className={st(classes.root, {
        isMobile,
        isRTL,
        alignment: settings.get(settingsParams.textAlignment),
        withSuffix: !!suffix,
      })}
    >
      <TextButton
        priority={TextButtonPriority.primary}
        data-hook={DataHooks.CollapsibleTitle}
        className={classes.collapsibleTitle}
        aria-expanded={!isCollapsed}
        suffixIcon={isCollapsed ? <ChevronDown /> : <ChevronUp />}
        onClick={toggleCollapse}
      >
        {title}
      </TextButton>
      {suffix}
    </div>
  ) : (
    <>
      <div
        className={st(classes.root, {
          isMobile,
          isRTL,
          alignment: settings.get(settingsParams.textAlignment),
          withSuffix: !!suffix,
        })}
      >
        <Text
          data-hook={DataHooks.Title}
          className={classes.title}
          tagName={AccessibilityHtmlTags.SecondaryHeading}
        >
          {title}
        </Text>
        {suffix}
      </div>
      <Divider className={classes.divider} data-hook={DataHooks.Divider} />
    </>
  );
};

export default SectionHeader;
