export const numOfSlots = 3;

export const DAILY_AGENDA_SLOT_HEIGHT = 172;
export const DESKTOP_DAILY_AGENDA_DATE_PICKER_HEIGHT = 190;
export const MOBILE_DAILY_AGENDA_DATE_PICKER_HEIGHT = 180;
export const STRETCHED_DAILY_AGENDA_SLOT_HEIGHT = 95;
export const DAILY_AGENDA_STRETCHED_FROM_WIDTH = 400;

export const TIMETABLE_SLOT_HEIGHT = 227;
export const WEEKLY_TIMETABLE_DATE_PICKER_HEIGHT = 105;

export const DESKTOP_CALENDAR_HEIGHT = 400;
export const MOBILE_CALENDAR_HEIGHT = 580;
