import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  isWeeklyTimetableLayout,
  isDailyAgendaWeeklyPickerLayout,
} from '../../../../../utils/layouts';
import { useSettingsParams } from '../../../Hooks/useSettingsParams';
import {
  WEEKLY_TIMETABLE_DATE_PICKER_HEIGHT,
  MOBILE_DAILY_AGENDA_DATE_PICKER_HEIGHT,
  DESKTOP_DAILY_AGENDA_DATE_PICKER_HEIGHT,
  numOfSlots,
  TIMETABLE_SLOT_HEIGHT,
  DAILY_AGENDA_SLOT_HEIGHT,
  STRETCHED_DAILY_AGENDA_SLOT_HEIGHT,
  MOBILE_CALENDAR_HEIGHT,
  DESKTOP_CALENDAR_HEIGHT,
  DAILY_AGENDA_STRETCHED_FROM_WIDTH,
} from './constants';

export type UseWidgetLoaderHeight = typeof useWidgetLoaderHeight;
export const useWidgetLoaderHeight = ({
  withDatePickerPlaceholder = false,
} = {}) => {
  const {
    isMobile,
    dimensions: { width },
  } = useEnvironment();
  const settings = useSettings();
  const settingsParams = useSettingsParams();

  const isWeeklyTimetable = isWeeklyTimetableLayout(settings, settingsParams);
  const isDailyAgenda = isDailyAgendaWeeklyPickerLayout(
    settings,
    settingsParams,
  );

  const getDatePickerPlaceholderHeight = () => {
    if (isWeeklyTimetable) {
      return WEEKLY_TIMETABLE_DATE_PICKER_HEIGHT;
    }

    if (isDailyAgenda) {
      return isMobile
        ? MOBILE_DAILY_AGENDA_DATE_PICKER_HEIGHT
        : DESKTOP_DAILY_AGENDA_DATE_PICKER_HEIGHT;
    }

    return 0;
  };

  const getSlotsHeight = () => {
    if (isWeeklyTimetable) {
      return numOfSlots * TIMETABLE_SLOT_HEIGHT;
    }

    if (isDailyAgenda) {
      const dailyAgendaSlotHeight =
        width < DAILY_AGENDA_STRETCHED_FROM_WIDTH
          ? DAILY_AGENDA_SLOT_HEIGHT
          : STRETCHED_DAILY_AGENDA_SLOT_HEIGHT;

      return numOfSlots * dailyAgendaSlotHeight;
    }

    return isMobile ? MOBILE_CALENDAR_HEIGHT : DESKTOP_CALENDAR_HEIGHT;
  };

  const datePickerHeight = withDatePickerPlaceholder
    ? getDatePickerPlaceholderHeight()
    : 0;
  const slotsHeight = getSlotsHeight();

  return datePickerHeight + slotsHeight;
};
